import React, { useEffect } from 'react'
import * as s from "./main.module.css"

// import useSize from '../utils/useSize';

import { gsap } from "gsap/dist/gsap";
import {DrawSVGPlugin} from 'gsap/dist/DrawSVGPlugin'

import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

import Icon from '../assets/article.svg';
import Quotes from '../assets/quotes.svg';
import { withpath } from '../utils/index.js'

import Art0 from '../assets/art0.svg';
import Art1 from '../assets/art1.svg';
import Art2 from '../assets/art2.svg';
import Art3 from '../assets/art3.svg';
import Art4 from '../assets/art4.svg';

import { Waypoint } from 'react-waypoint';

const Main = (p) => {

	const data = p.data
	const waypercent = "20%"

	gsap.config({
		autoSleep: 60,
		force3D: false,
		nullTargetWarn: false
	});
	gsap.registerPlugin(DrawSVGPlugin);

	useEffect(() => {

		let art0table = document.querySelectorAll(`#art0table`);
		let art0plate0 = document.querySelectorAll(`#art0plate0`);
		let art0plate1 = document.querySelectorAll(`#art0plate1`);
		let art0plate2 = document.querySelectorAll(`#art0plate2`);
		let art0smoke = document.querySelectorAll(`.art0smoke`);

		gsap.killTweensOf([art0table,art0plate0,art0plate1,art0plate2,art0smoke]);
		gsap.set(art0table, {opacity: 0})
		gsap.set(art0plate0, {opacity: 0, x: 200, rotate: -40, transformOrigin: "50% 50%"})
		gsap.set(art0plate1, {opacity: 0, x: 200, rotate: 40, transformOrigin: "50% 50%"})
		gsap.set(art0plate2, {opacity: 0, x: -200, rotate: -40, transformOrigin: "50% 50%"})
		gsap.set(art0smoke, {opacity: 0})

		let art0smokep0 = document.querySelector(`.art0smokep0`);
     	let tl0 = gsap.timeline({ paused: false, repeat: -1});
		tl0.set(art0smokep0, {drawSVG:"0% 0%" });
		tl0.to(art0smokep0, {drawSVG:"0% 100%", duration: 0.75});
		tl0.to(art0smokep0, {drawSVG:"100% 100%", duration: 0.75});
		tl0.to(art0smokep0, {drawSVG:"100% 100%", duration: 5.0});

		let art0smokep1 = document.querySelector(`.art0smokep1`);
     	let tl1 = gsap.timeline({ paused: false, repeat: -1, delay: 0.2});
		tl1.set(art0smokep1, {drawSVG:"0% 0%" });
		tl1.to(art0smokep1, {drawSVG:"0% 100%", duration: 0.75});
		tl1.to(art0smokep1, {drawSVG:"100% 100%", duration: 0.75});
		tl1.to(art0smokep1, {drawSVG:"100% 100%", duration: 5.0});



		let art1rect = document.querySelectorAll(`#art1rect`);
		let art1circle = document.querySelectorAll(`#art1circle`);
		let art1sector = document.querySelectorAll(`#art1sector`);
		gsap.killTweensOf([art1rect,art1circle,art1sector]);
		gsap.set(art1rect, {opacity: 0, scale: 0.5, transformOrigin:"50% 50%"})
		gsap.set(art1circle, {opacity: 0, scale: 0.5, transformOrigin:"50% 50%"})
		gsap.set(art1sector, {opacity: 0, y: 10, x:3, rotate: -20, transformOrigin: "0% 100%"})

		let art2flor = document.querySelectorAll(`#art2flor`);
		let art2man = document.querySelectorAll(`.art2man`);
		let art2globe = document.querySelectorAll(`#art2globe`);
		let art2arr = document.querySelectorAll(`#art2arr`);
		gsap.killTweensOf([art2flor,art2man,art2globe,art2arr]);
		gsap.set(art2flor, {opacity: 0})
		gsap.set(art2man, {opacity: 0, transformOrigin: "50% 50%"})
		gsap.set(art2globe, {opacity: 0, rotate: -30, x: -30, transformOrigin: "50% 50%"})
		gsap.set(art2arr, {opacity: 0, x: -20, transformOrigin: "50% 50%"})

		let art3sun = document.querySelectorAll(`#art3sun`);
		let art3floor = document.querySelectorAll(`#art3floor`);
		let art3roof = document.querySelectorAll(`#art3roof`);
		let art3col = document.querySelectorAll(`.art3col`);
		gsap.killTweensOf([art3sun,art3floor,art3roof,art3col]);
		gsap.set(art3sun, {opacity: 0, y:40, x:10})
		gsap.set(art3floor, {opacity: 0, x: -20, transformOrigin: "50% 50%"})
		gsap.set(art3roof, {opacity: 0, x: 20, transformOrigin: "50% 50%"})
		gsap.set(art3col, {opacity: 0, transformOrigin: "50% 50%"})

		let art4bar = document.querySelectorAll(`.art4bar`);
		let art4arrow = document.querySelectorAll(`.art4arrow`);
		gsap.killTweensOf([art4bar,art4arrow]);
		gsap.set(art4bar, {opacity: 0, scaleY: 0, transformOrigin: "50% 100%"})
		gsap.set(art4arrow, {opacity: 0, y: 30, transformOrigin: "50% 50%"})

	},[])


	function enter0(){
		let art0table = document.querySelectorAll(`#art0table`);
		let art0plate0 = document.querySelectorAll(`#art0plate0`);
		let art0plate1 = document.querySelectorAll(`#art0plate1`);
		let art0plate2 = document.querySelectorAll(`#art0plate2`);
		let art0smoke = document.querySelectorAll(`.art0smoke`);

		gsap.killTweensOf([art0table,art0plate0,art0plate1,art0plate2,art0smoke]);
		gsap.set(art0table, {opacity: 0})
		gsap.set(art0plate0, {opacity: 0, x: 200, rotate: -40, transformOrigin: "50% 50%"})
		gsap.set(art0plate1, {opacity: 0, x: 200, rotate: 40, transformOrigin: "50% 50%"})
		gsap.set(art0plate2, {opacity: 0, x: -200, rotate: -40, transformOrigin: "50% 50%"})
		gsap.set(art0smoke, {opacity: 0})

		let tl = gsap.timeline({ paused: false });
			tl.to(art0table, {opacity: 1, duration: 1, ease: `power1.out` }, 0.0);
			tl.to(art0plate0, {opacity: 1, x: 0, rotate: 0, duration: 1, ease: `power1.out` }, 0.3);
			tl.to(art0plate1, {opacity: 1, x: 0, rotate: 0, duration: 1, ease: `power1.out` }, 0.4);
			tl.to(art0plate2, {opacity: 1, x: 0, rotate: 0, duration: 1, ease: `power1.out` }, 0.5);
			tl.to(art0smoke, {opacity: 1, duration: 1, ease: `power1.out`, stagger: 0.1}, 0.7);

	}

	function leave0(){
		let art0table = document.querySelectorAll(`#art0table`);
		let art0plate0 = document.querySelectorAll(`#art0plate0`);
		let art0plate1 = document.querySelectorAll(`#art0plate1`);
		let art0plate2 = document.querySelectorAll(`#art0plate2`);
		let art0smoke = document.querySelectorAll(`.art0smoke`);

		gsap.killTweensOf([art0table,art0plate0,art0plate1,art0plate2,art0smoke]);

		let tl = gsap.timeline({ paused: false });
			tl.to(art0table, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art0plate0, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art0plate1, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art0plate2, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art0smoke, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
	}

	function enter1(){
		let art1rect = document.querySelectorAll(`#art1rect`);
		let art1circle = document.querySelectorAll(`#art1circle`);
		let art1sector = document.querySelectorAll(`#art1sector`);

		gsap.killTweensOf([art1rect,art1circle,art1sector]);
		gsap.set(art1rect, {opacity: 0, scale: 0.5, transformOrigin: "50% 50%"})
		gsap.set(art1circle, {opacity: 0, scale: 0.5, transformOrigin: "50% 50%"})
		gsap.set(art1sector, {opacity: 0, y: 10, x:3, rotate: -20, transformOrigin: "0% 100%"})

		let tl = gsap.timeline({ paused: false });
			tl.to(art1rect, {opacity: 1, scale: 1, duration: 1, ease: `power1.out` }, 0.0);
			tl.to(art1circle, {opacity: 1, scale: 1, duration: 1, ease: `power1.out` }, 0.3);
			tl.to(art1sector, {opacity: 1, y: 0, x: 0, rotate: 0, duration: 0.5, ease: `power1.out` }, 0.6);

	}

	function leave1(){
		let art1rect = document.querySelectorAll(`#art1rect`);
		let art1circle = document.querySelectorAll(`#art1circle`);
		let art1sector = document.querySelectorAll(`#art1sector`);

		gsap.killTweensOf([art1rect,art1circle,art1sector]);

		let tl = gsap.timeline({ paused: false });
			tl.to(art1rect, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art1circle, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art1sector, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
	}

	function enter2(){
		let art2flor = document.querySelectorAll(`#art2flor`);
		let art2man = document.querySelectorAll(`.art2man`);
		let art2globe = document.querySelectorAll(`#art2globe`);
		let art2arr = document.querySelectorAll(`#art2arr`);

		gsap.killTweensOf([art2flor,art2man,art2globe,art2arr]);
		gsap.set(art2flor, {opacity: 0})
		gsap.set(art2man, {opacity: 0, scale:0.9, transformOrigin: "50% 100%"})
		gsap.set(art2globe, {opacity: 0, rotate: -30, x:-30, transformOrigin: "50% 50%"})
		gsap.set(art2arr, {opacity: 0, x: -20, transformOrigin: "50% 50%"})

		let tl = gsap.timeline({ paused: false });
			tl.to(art2flor, {opacity: 1, duration: 1, ease: `power1.out`}, 0.0);
			tl.to(art2man, {opacity: 1, duration: 1, scale: 1, ease: `power2.out`, stagger: 0.3 }, 0.2);
			tl.to(art2globe, {opacity: 1, rotate: 0, x:0, duration: 1, ease: `power1.out`}, 0.8);
			tl.to(art2arr, {opacity: 1, x: 0, duration: 1, ease: `power1.out`}, 1.0);

	}

	function leave2(){
		let art2flor = document.querySelectorAll(`#art2flor`);
		let art2man = document.querySelectorAll(`.art2man`);
		let art2globe = document.querySelectorAll(`#art2globe`);
		let art2arr = document.querySelectorAll(`#art2arr`);

		gsap.killTweensOf([art2flor,art2man,art2globe,art2arr]);

		let tl = gsap.timeline({ paused: false });
			tl.to(art2flor, {opacity: 0, duration: 0.2, ease: `power1.out`}, 0.0);
			tl.to(art2man, {opacity: 0, duration: 0.2, ease: `power2.out`, stagger: 0.0 }, 0.0);
			tl.to(art2globe, {opacity: 0, duration: 0.2, ease: `power1.out`}, 0.0);
			tl.to(art2arr, {opacity: 0, duration: 0.2, ease: `power1.out`}, 0.0);
	}

	function enter3(){
		let art3sun = document.querySelectorAll(`#art3sun`);
		let art3floor = document.querySelectorAll(`#art3floor`);
		let art3roof = document.querySelectorAll(`#art3roof`);
		let art3col = document.querySelectorAll(`.art3col`);

		gsap.killTweensOf([art3sun,art3floor,art3roof,art3col]);
		gsap.set(art3sun, {opacity: 0, y:40, x:10})
		gsap.set(art3floor, {opacity: 0, x: -20, transformOrigin: "50% 50%"})
		gsap.set(art3roof, {opacity: 0, x: 20, transformOrigin: "50% 50%"})
		gsap.set(art3col, {opacity: 0, transformOrigin: "50% 50%"})
		
		let tl = gsap.timeline({ paused: false });
			tl.to(art3sun, {opacity: 1, y: 0, x: 0, duration: 2, ease: `power1.out` }, 0.0);
			tl.to(art3floor, {opacity: 1, x: 0, duration: 1, ease: `power1.out`}, 0.0);
			tl.to(art3roof, {opacity: 1, x: 0, duration: 1, ease: `power1.out`}, 0.2);
			tl.to(art3col, {opacity: 1, duration: 1, ease: `power2.out`, stagger: 0.2 }, 0.4);
	}

	function leave3(){
		let art3sun = document.querySelectorAll(`#art3sun`);
		let art3floor = document.querySelectorAll(`#art3floor`);
		let art3roof = document.querySelectorAll(`#art3roof`);
		let art3col = document.querySelectorAll(`.art3col`);

		gsap.killTweensOf([art3sun,art3floor,art3roof,art3col]);
		
		let tl = gsap.timeline({ paused: false });
			tl.to(art3sun, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art3floor, {opacity: 0, duration: 0.2, ease: `power1.out`}, 0.0);
			tl.to(art3roof, {opacity: 0, duration: 0.2, ease: `power1.out`}, 0.0);
			tl.to(art3col, {opacity: 0, duration: 0.2, ease: `power2.out`}, 0.0);
	}

	function enter4(){
		let art4bar = document.querySelectorAll(`.art4bar`);
		let art4arrow = document.querySelectorAll(`.art4arrow`);
		gsap.killTweensOf([art4bar,art4arrow]);
		gsap.set(art4bar, {opacity: 0, scaleY: 0, transformOrigin: "50% 100%"})
		gsap.set(art4arrow, {opacity: 0, y: 30, transformOrigin: "50% 50%"})
		
		let tl = gsap.timeline({ paused: false });
			tl.to(art4bar, {opacity: 1, scaleY: 1, duration: 1, ease: `power1.out`, stagger: 0.2 }, 0.0);
			tl.to(art4arrow, {opacity: 1, y: 0, duration: 0.5, ease: `power2.out`, stagger: 0.1 }, 0.8);
	}

	function leave4(){
		let art4bar = document.querySelectorAll(`.art4bar`);
		let art4arrow = document.querySelectorAll(`.art4arrow`);
		gsap.killTweensOf([art4bar,art4arrow]);

		let tl = gsap.timeline({ paused: false });
			tl.to(art4bar, {opacity: 0, duration: 0.2, ease: `power1.out`, stagger: 0.0 }, 0.0);
			tl.to(art4arrow, {opacity: 0, y: 0, duration: 0.2, ease: `power1.out`, stagger: 0.0 }, 0.0);
	}

	return (
		<section className={s.article} >
			

			<div 
				className={s.headerbg} 
				style={{backgroundImage: `url(${withpath(`/img/bg2.jpg`)})` }}
			>

				<div className={s.svgbg}
					style={{backgroundImage: `url(${withpath(`/img/cover.svg`)})` }}
				/>
			
				<div className={s.headerbox}>
					<div className={s.hicon}>
						<Icon />
					</div>
					<div className={s.h0} dangerouslySetInnerHTML={{__html: data.h0 }} />
					<div className={s.desc} dangerouslySetInnerHTML={{__html: data.desc }} />
				</div>
			</div>

			<div className={s.box} data-id={0}>
				<div className={s.tbox} data-id={0}>
					<div className={s.tboxl} data-id={0}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[0] }} data-id={0} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[1] }} data-id={1} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[2] }} data-id={2} />
					</div>
					<div className={s.tboxr} data-id={0}>
						<Waypoint 
							onEnter={(p) => {enter0()}} 
							onLeave={(p) => {leave0()}} 
							bottomOffset={waypercent} 
							scrollableAncestor={`window`} 
							fireOnRapidScroll={false}
						>
						<div className={s.art0}>
							<Art0 />
						</div>
						</Waypoint>
					</div>

					<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[0] }} data-id={"00"} />

				</div>
			</div>

			<div className={s.box} data-id={1}>
				<div className={s.tbox} data-id={1}>
					<div className={s.tboxl} data-id={1}>
						<Waypoint 
							onEnter={(p) => {enter1()}} 
							onLeave={(p) => {leave1()}} 
							bottomOffset={waypercent} 
							scrollableAncestor={`window`} 
							fireOnRapidScroll={false}
						>
						<div className={s.art1}>
							<Art1 />
						</div>
						</Waypoint>
					</div>
					<div className={s.tboxr} data-id={1}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[3] }} data-id={3} />
					</div>
				</div>

				<div className={s.tbox} data-id={2}>
					<div className={s.tboxl} data-id={2}>
						<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<div className={s.quotesbox}>
							<div className={s.quotes}>
								<Quotes />
							</div>
							<div className={s.quotestxt} dangerouslySetInnerHTML={{__html: data.quotes[0].txt }} />
							<div className={s.quoteshr} />
							<div className={s.quotesname} dangerouslySetInnerHTML={{__html: data.quotes[0].name }} />
						</div>
						</ScrollAnimation>
					</div>
					<div className={s.tboxr} data-id={2}>
						<div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[4] }} data-id={0} />
						<div className={s.hr} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[5] }} data-id={5} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[6] }} data-id={6} />
					</div>
				</div>
			</div>


			<div className={s.box} data-id={2}>
				
				<div className={s.tbox} data-id={3}>
					<div className={s.tboxl} data-id={3}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[7] }} data-id={7} />
					</div>
					<div className={s.tboxr} data-id={3}>
						<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<div className={s.quotesbox}>
							<div className={s.quotes}>
								<Quotes />
							</div>
							<div className={s.quotestxt} dangerouslySetInnerHTML={{__html: data.quotes[1].txt }} />
						</div>
						</ScrollAnimation>
					</div>
				</div>

				<div className={s.tbox} data-id={4}>
					<div className={s.tboxl} data-id={4}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[8] }} data-id={8} />
					</div>
					<div className={s.tboxr} data-id={4}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[9] }} data-id={9} />
					</div>
				</div>

			</div>

			<div className={s.box} data-id={3}>
				<div className={s.tbox} data-id={5}>
					<div className={s.tboxl} data-id={5}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[10] }} data-id={10} />
					</div>
					<div className={s.tboxr} data-id={5}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[11] }} data-id={11} />
					</div>
				</div>

				<div className={s.tbox} data-id={6}>
						<Waypoint 
							onEnter={(p) => {enter2()}} 
							onLeave={(p) => {leave2()}} 
							bottomOffset={waypercent} 
							scrollableAncestor={`window`} 
							fireOnRapidScroll={false}
						>
						<div className={s.art2}>
							<Art2 />
						</div>
						</Waypoint>
				</div>

				<div className={s.tbox} data-id={7}>
					<div className={s.tboxl} data-id={7}>
						<div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[12] }} data-id={12} />
						<div className={s.hr} data-id={7}/>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[13] }} data-id={13} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[14] }} data-id={14} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[15] }} data-id={15} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[16] }} data-id={16} />
					</div>
					<div className={s.tboxr} data-id={7}>
						<Waypoint 
							onEnter={(p) => {enter3()}} 
							onLeave={(p) => {leave3()}} 
							bottomOffset={waypercent} 
							scrollableAncestor={`window`} 
							fireOnRapidScroll={false}
						>
						<div className={s.art3}>
							<Art3 />
						</div>
						</Waypoint>
					</div>
				</div>

			</div>

			<div className={s.box} data-id={4}>
				
				<div className={s.tbox} data-id={8}>
					<div className={s.tboxl} data-id={8}>
						<div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[17] }} data-id={17} />
						<div className={s.hr} data-id={8}/>
					</div>
					<div className={s.tboxr} data-id={8}>

					</div>
				</div>

				<div className={s.tbox} data-id={9}>
					<div className={s.tboxl} data-id={9}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[18] }} data-id={18} />
						<div className={s.artbox}>
							<div className={s.artname} dangerouslySetInnerHTML={{__html: data.txt[22] }} />
							<Waypoint 
								onEnter={(p) => {enter4()}} 
								onLeave={(p) => {leave4()}} 
								bottomOffset={waypercent} 
								scrollableAncestor={`window`} 
								fireOnRapidScroll={false}
							>
							<div className={s.art4}>
								<Art4 />
								<div className={s.legend}>
									<div className={s.axis} dangerouslySetInnerHTML={{__html: data.txt[23] }} />
									<div className={s.source} dangerouslySetInnerHTML={{__html: data.txt[24] }} />
								</div>
							</div>
							</Waypoint>
						</div>
					</div>
					<div className={s.tboxr} data-id={9}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[19] }} data-id={19} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[20] }} data-id={20} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[21] }} data-id={21} />
					</div>
				</div>

			</div>

		</section>
)}

export default Main
